import { firstItem } from "~/src/lib/iterable"
import { firstKey } from "~/src/lib/object"

/**
 * Represents anything nullish. Useful with `isNil` and `isntNil` methods.
 */
export type Nil = null | undefined

/**
 * Ruby-like definition of falsy in a type.
 */
export type Falsy = Nil | false

/**
 * Represents a value that can be awaited or any value that can be prefixed with `await` (which is anything).
 */
export type Awaitable<T> = T | PromiseLike<T>

export const isArray = Array.isArray

export function identity(x) {
  return x
}

/**
 * In TS, `args` are treated as a tuple. Use this property to force an array
 * to be treated as a tuple.
 * @param xs
 * @returns
 */
export function tuple<T extends any[]>(...xs: T) {
  return xs
}

export function isBlank(x) {
  return !isPresent(x)
}

/**
 * Predicate returning `true` if `x` is `null`, `undefined` or `false`. Doesn't
 * follow JS' typical "falsy" rules.
 * @param x
 */
export function isFalsy(x: unknown): x is Falsy {
  return x === void 0 || x === null || x === false
}

export function isString(x: unknown): x is string {
  return typeof x === "string"
}

export function isntString<T>(x: T): x is Exclude<T, string> {
  return !isString(x)
}

/**
 * Predicate returning `true` if `x` is not falsy.
 * @param x
 */
export function isTruthy<T>(x: T): x is Exclude<T, Falsy> {
  return !isFalsy(x)
}

export function isFn(x: unknown): x is Function {
  return typeof x === "function"
}

/**
 * Predicate returning `true` if `x` supports iterable interface.
 * @param x
 */
export function isIterable(x) {
  return typeof x?.[Symbol.iterator] === "function"
}

/**
 * Predicate returning `true` if `x` is nullish (`null` or `undefined`).
 * @param x
 */
export function isNil(x: unknown): x is Nil {
  return x == null
}

/**
 * Predicate returning `true` if `x` isn't nullish (`null` or `undefined`).
 * @param x
 */
export function isntNil<T>(x: T): x is Exclude<T, Nil> {
  return x != null
}

/**
 * Predicate returning `true` if `x` is a plain object.
 * @param x
 */
export function isObject(x) {
  return x?.constructor === Object
}

/**
 * Predicate returning `true` if `x` is not nil and contains at least one value (e.g. there's at least one key,
 * and there's at least one iterable item.)
 *
 * @param x
 */
export function isPresent(x) {
  if (isObject(x)) return !isNil(firstKey(x))
  if (isIterable(x)) return !isNil(firstItem(x))
  return !isNil(x)
}

/**
 * TODO: Maybe move to iterable
 * @param {*} x
 */
export function hasOne(x) {
  if (!isIterable(x)) return false

  let count = 0
  for (const _ of x) {
    count += 1

    if (count > 1) return false
  }

  return count === 1
}

/**
 * Returns default if x is nullish
 * @param dflt default
 * @param x
 */
export function optionOr<T>(dflt: T, x: T): T {
  if (isNil(x)) return dflt
  return x
}

/**
 * Returns `undefined` if x is blank, otherwise returns x
 * @param x
 */
export function presence<T>(x: string): string | undefined
export function presence<T>(x: T[]): T[] | undefined
export function presence<K extends string | symbol | number, T>(x: Record<K, T>): Record<K, T> | undefined
export function presence<K, T>(x: Map<K, T>): Map<K, T> | undefined
export function presence<T>(x: Set<T>): Set<T> | undefined
export function presence<T>(x: Object): Object | undefined
export function presence(x: any): any | undefined {
  /**
   * Why void 0?
   * The problem with using undefined is that it's not a reserved word (it is actually a property of the global object
   * [wtfjs](https://web.archive.org/web/20160311231335/http://wtfjs.com/2010/02/15/undefined-is-mutable)). That is,
   * undefined is a permissible variable name, so you could assign a new value to it at your own caprice, or library
   * authors could use it as a variable name, causing unexpected behavior.
   * @see https://stackoverflow.com/questions/7452341/what-does-void-0-mean
   */
  if (isNil(x)) return void 0
  if (isString(x) && x.trim().length === 0) return void 0
  if (isArray(x) && x.length === 0) return void 0
  if ((x instanceof Map || x instanceof Set) && x.size === 0) return void 0
  if (isObject(x) && Object.keys(x).length === 0) return void 0

  return x
}
